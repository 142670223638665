export const environment = {
	production: true,
	authURL: 'https://logosuni.laba.biz/identityserver/',
	apiURL: 'https://logosuni.laba.biz/logosuni.servicesv2/api/',
	clientId: '98C96373243D',
	clientSecret: 'B1355BBB-EA35-4724-AFAA-8ABAAFEDCFB6',
	scope: 'LogosUni.Laba.Api',
	oneSignalAppId: '80d4dc94-0b2c-416c-b0fa-066e3f9baac1',
	googleProjectId: 'laba-firenze',
	version: '1.2.2'
};
